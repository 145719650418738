/**
 * Performs an HTTP request using Axios with specified method, endpoint, and data
 * @param props - Object containing method, endpoint, and data
 * @returns Promise resolving to the response data
 */

import axios from "axios";
// import { Auth } from "aws-amplify";

const baseURL = import.meta.env.VITE_API_ENDPOINT || "";

interface Props {
  method: "GET" | "POST";
  endpoint: string;
  data?: any;
  params?: any;
  noAuth?: boolean;
}

export const axiosRequest = async (props: Props) => {
  const { method, endpoint, data, params, noAuth = false } = props;

  // Combine baseURL with endpoint
  const url = `${baseURL}${endpoint}`;

  // Log data and params if they exist and in development mode
  if (import.meta.env.DEV) {
    if (data) {
      console.log(`Request data from ${endpoint}:`, data);
    }
    if (params) {
      console.log(`Request params from ${endpoint}:`, params);
    }
  }

  // Set headers, add authorization if not disabled
  let headers: object | undefined = undefined;
  if (!noAuth) {
    const accessToken = Object.entries(localStorage).find(
      ([key]) => key.startsWith("CognitoIdentityServiceProvider") && key.endsWith("accessToken")
    )?.[1];

    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
      };
    }
  }

  // Make the request
  const response = await axios.request({
    headers: headers,
    method: method,
    url: url,
    data: data,
    params: params,
  });

  // Throw an error if the response status is not in the 200-299 range
  if (response.status < 200 || response.status >= 300) {
    throw new Error("Error with Axios request: " + response.status + ": " + response.data);
  }

  // Log the response if in development mode
  if (import.meta.env.DEV) {
    console.log("Response from", endpoint, ":", response);
  }

  // Return the response data
  return response.data;
};
