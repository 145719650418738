import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";

// Local
import { axiosRequest } from "../utils/axiosRequest";
import { JobStatus } from "../models/JobStatus";
import { requestReportMutationKey } from "./queryKeys";
import { useContext } from "react";
import { SnackbarContext } from "../context/SnackbarContext";

interface RequestReportParams {
  platform: string;
  handle: string;
  limit: number;
  template: string;
  email: string;
  domain: string;
  job_status: JobStatus;
}

const useRequestReport = () => {
  const navigate = useNavigate();
  const { showSnackbar } = useContext(SnackbarContext);

  return useMutation({
    mutationKey: [requestReportMutationKey],
    mutationFn: (data: RequestReportParams) =>
      axiosRequest({
        method: "POST",
        endpoint: "/createJob",
        data,
      }),
    onError: (error) => {
      console.error("Error requesting report:", error);
      showSnackbar("Error requesting report", "error");
    },
    onSuccess: (data) => {
      console.log("Report requested successfully:", data);
      if (data.status === JobStatus.GettingData) {
        navigate({ to: "/reports", params: { id: data.job_id } });
      }
    },
  });
};

export default useRequestReport;
