const JobStatus = {
    Created: "created",
    Queued: "queued",
    GettingData: "getting_data",
    AnalyzingData: "analyzing_data",
    Completed: "completed",
    Failed: "failed",
    NoData: "no_data",
    Private: "private_account"
  } as const;
  
  export type JobStatus = (typeof JobStatus)[keyof typeof JobStatus];
  
  export { JobStatus };