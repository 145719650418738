import { Box, Typography } from "@mui/material";
import { useState } from "react";
import EmailDialogBox from "../components/EmailDialogBox/EmailDialogBox";
import { useUser } from "../hooks/useUser";

interface Props {
    text?: string;
}
const PleaseLogin = ({ text }: Props) => {
  const user = useUser();

  const [loginOpen, setLoginOpen] = useState<boolean>(false);

  if (user.user) {
    return null;
  }
  return (
    <Box sx={{ my: 4 }}>
      <Typography variant="body1" gutterBottom>
        You are currently logged out.{" "}
        <Typography
          component="span"
          sx={{
            fontWeight: "bold",
            color: "secondary.main",
            cursor: "pointer"
          }}
          onClick={() => setLoginOpen(true)}
        >
          Click here to log in
        </Typography>{" "}
        {text || "to use this page."}
      </Typography>
      <EmailDialogBox
        open={loginOpen}
        toggleOpen={() => setLoginOpen(false)}
        isLoginOnly={true}
        selectedPlatform={null}
      />
    </Box>
  );
};

export default PleaseLogin;
