import React from "react";
import { Box } from "@mui/material";
import { useNavigate } from "@tanstack/react-router";

interface LogoProps {
  height?: number;
}

const Logo: React.FC<LogoProps> = () => {
  const navigate = useNavigate();

  return (
    <Box
      component="img"
      // src="/sh-logo-cropped.png"
      src="/sh-logo-opaque.jpg"
      alt="SocialHistory.ai"
      sx={{
        height: { xs: 30, sm: 35, md: 40 },
        cursor: "pointer",
      }}
      onClick={() => navigate({ to: "/" })}
    />
  );
};

export default Logo;
