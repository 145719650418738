import { CircularProgress, Container, Stack, Typography } from "@mui/material";

// Reusable Circular Loading Component

interface Props {
  message?: string;
  isSmall?: boolean;
}

const Loading = ({ message, isSmall }: Props) => {
  return (
    <Container
      sx={{
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        my: "auto",
      }}
    >
      <Stack direction="column" alignItems="center" spacing={2}>
        <CircularProgress color="secondary" size={isSmall ? 20 : 40} />
        {message && <Typography variant="body1">{message}</Typography>}
      </Stack>
    </Container>
  );
};

export default Loading;
