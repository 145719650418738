import React, { useState, useCallback } from "react";
import { Modal, Box } from "@mui/material";
import { ModalContext } from "./ModalContext";

/**
 * Interface for Modal options
 */
interface ModalOptions {
  content: React.ReactNode;
  title?: string;
}

/**
 * ModalProvider component
 *
 * This component provides a context for displaying modal dialogs
 * throughout the application.
 *
 * @param {Object} props - The component props
 * @param {React.ReactNode} props.children - The child components
 */
export const ModalProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  // State to control the visibility of the modal
  const [open, setOpen] = useState(false);
  // State to store the current modal options
  const [options, setOptions] = useState<ModalOptions>({
    content: null,
    title: "",
  });

  /**
   * Function to show the modal with content and an optional title
   *
   * @param {React.ReactNode} content - The content to display in the modal
   * @param {string} title - The optional title of the modal
   */
  const showModal = useCallback((content: React.ReactNode, title?: string) => {
    setOptions({ content, title });
    setOpen(true);
  }, []);

  /**
   * Function to close the modal
   */
  const hideModal = useCallback(() => {
    setOpen(false);
  }, []);

  return (
    <ModalContext.Provider value={{ showModal, hideModal }}>
      {children}
      <Modal
        open={open}
        onClose={hideModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          {options.title && (
            <h2 id="modal-title" style={{ marginTop: 0 }}>
              {options.title}
            </h2>
          )}
          <div id="modal-description">{options.content}</div>
        </Box>
      </Modal>
    </ModalContext.Provider>
  );
};