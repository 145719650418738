import React, { Suspense, useEffect, useState } from "react";
import Loading from "../../navigation/Loading";

// Lazy load ChatBotToggle
const ChatBotToggle = React.lazy(() => import("./ChatBot"));

const ChatBotWrapper: React.FC = () => {
  const [shouldRenderChatBot, setShouldRenderChatBot] = useState(false);



  useEffect(() => {
    const timer = setTimeout(() => setShouldRenderChatBot(true), 3000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      {shouldRenderChatBot && (
        <Suspense fallback={<Loading />}>
          <ChatBotToggle />
        </Suspense>
      )}
    </>
  );
};

export default ChatBotWrapper;
