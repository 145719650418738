const awsConfig = {
  Auth: {
    Cognito: {
      region: import.meta.env.VITE_COGNITO_REGION,
      userPoolId: import.meta.env.VITE_COGNITO_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_COGNITO_APP_CLIENT_ID,
      // mandatorySignIn: true,
      authenticationFlowType: "CUSTOM_AUTH",
    },
    federatedSignIn: {provider: "Google"}
  },
};

export default awsConfig;
