import { useContext, } from "react";

// Local
import { UserContext, UserContextType } from "../auth/UserContext";

// Custom hook to access the user context
export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
};
