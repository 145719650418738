import React, { useState, useCallback } from "react";
import { Snackbar, Alert, AlertColor } from "@mui/material";
import { SnackbarContext } from "./SnackbarContext";

/**
 * Interface for Snackbar options
 */
interface SnackbarOptions {
	message: string;
	severity: AlertColor;
}

/**
 * SnackbarProvider component
 *
 * This component provides a context for displaying snackbar notifications
 * throughout the application.
 *
 * @param {Object} props - The component props
 * @param {React.ReactNode} props.children - The child components
 */
export const SnackbarProvider: React.FC<{ children: React.ReactNode }> = ({
	children
}) => {
	// State to control the visibility of the snackbar
	const [open, setOpen] = useState(false);
	// State to store the current snackbar options
	const [options, setOptions] = useState<SnackbarOptions>({
		message: "",
		severity: "info"
	});

	/**
	 * Function to show the snackbar with a message and severity
	 *
	 * @param {string} message - The message to display in the snackbar
	 * @param {AlertColor} severity - The severity level of the message
	 */
	const showSnackbar = useCallback((message: string, severity: AlertColor) => {
		setOptions({ message, severity });
		setOpen(true);
	}, []);

	/**
	 * Function to close the snackbar
	 */
	const handleClose = useCallback(() => {
		setOpen(false);
	}, []);

	return (
		<SnackbarContext.Provider value={{ showSnackbar }}>
			{children}
			<Snackbar
				open={open}
				autoHideDuration={3000}
				onClose={handleClose}
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			>
				<Alert
					onClose={handleClose}
					severity={options.severity}
					sx={{ width: "100%",}}
				>
					{options.message}
				</Alert>
			</Snackbar>
		</SnackbarContext.Provider>
	);
};
