import { createContext, useState, useEffect, useMemo, useCallback } from "react";
import { getCurrentUser, signOut } from "aws-amplify/auth";
import { Amplify } from "aws-amplify";
import awsConfig from "./aws-exports";
import { axiosRequest } from "../utils/axiosRequest";
import { UserClass } from "../models/Users";
import { useQuery } from "@tanstack/react-query";
import { userQueryKey } from "../hooks/queryKeys";
import Loading from "../navigation/Loading";

/**
 * User is assembled from Cognito AuthUser and DB Data
 * User Class is provided from User.ts
 * This function is used to assemble that data and
 * Provide it as context to the app
 * 
 * useUser() hook gets the context for any component
 */

export const UserContext = createContext<UserContextType | undefined>(undefined);
Amplify.configure(awsConfig);

export interface UserContextType {
  user: UserClass | null;
  fetchAuthUser: () => Promise<void>;
  logout: () => Promise<void>;
  isLoading: boolean;
}

/* Gets the user from Cognito, and then the DB */
export const UserContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [authUser, setAuthUser] = useState<string | null>(null);
  const [isInitializing, setIsInitializing] = useState(true);

  /* Cognito User - Runs on Mount
   */
  const fetchAuthUser = useCallback(async () => {
    // Look for any key in localStorage that starts with 'CognitoIdentityServiceProvider'
    const cognitoKey = Object.keys(localStorage).find((key) =>
      key.startsWith("CognitoIdentityServiceProvider")
    );

    if (!cognitoKey) {
      setAuthUser(null);
      setIsInitializing(false);
      return;
    }

    try {
      const currentUser = await getCurrentUser();
      const userEmail = currentUser?.signInDetails?.loginId;
      setAuthUser(userEmail || null);
    } catch (error: any) {
      if (error.name === "UserUnAuthenticatedException") {
        console.log("User is not authenticated.");
        setAuthUser(null);
      } else {
        console.error("Error fetching auth user:", error);
      }
    } finally {
      setIsInitializing(false);
    }
  }, []);

  useEffect(() => {
    fetchAuthUser();
  }, [fetchAuthUser]);

  const logout = useCallback(async () => {
    await signOut();
    setAuthUser(null);
    localStorage.removeItem("amplify-signin-with-hostedUI");
  }, []);

  /* DB User - Runs on Mount, staletime, or queryKey invalidation
   */
  const { data, error, isLoading } = useQuery({
    queryKey: [userQueryKey, authUser],
    queryFn: () => {
      return axiosRequest({
        method: "GET",
        endpoint: `/getUser`,
        params: { email: authUser },
      });
    },
    enabled: !!authUser,
    staleTime: 15 * 60 * 1000, // 15 minutes
  });

  useEffect(() => {
    if (error) {
      console.error("Error fetching user data:", error);
    }
  }, [error]);

  /* Assembles the user from Cognito and DB */
  const contextValue = useMemo(() => {
    if (!authUser) {
      return { user: null, fetchAuthUser, logout, isLoading: false };
    }
    if (data) {
      const user = new UserClass(authUser, data);
      return { user, fetchAuthUser, logout, isLoading };
    }
    return { user: null, fetchAuthUser, logout, isLoading };
  }, [authUser, data, fetchAuthUser, logout, isLoading]);

  if (isInitializing) {
    return <Loading message="Authenticating user..." />;
  }

  /* Returns the assembled user to the app

   */
  return <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>;
};
