import { Stack, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
import { ReactNode, Suspense } from "react";
import { ErrorBoundary } from "react-error-boundary";
import Loading from "./Loading";

interface Props {
  children?: ReactNode;
}

const ErrorWrapper: React.FC<Props> = ({ children }) => {
  return (
    <QueryErrorResetBoundary>
      {({ reset }) => (
        <ErrorBoundary
          fallbackRender={({ error, resetErrorBoundary }) => (
            <div>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography variant="h1" sx={{ my: 3 }}>
                Oops! My bad.
              </Typography>
              <Button variant="outlined" onClick={() => resetErrorBoundary()} sx={{height: "fit-content"}}>Try again!</Button>
             
              </Stack> <Typography variant="body1" sx={{ my: 3, fontFamily: "monospace" }}>
                Error message: {error.message}
              </Typography>
            </div>
          )}
          onReset={reset}
        >
          <Suspense fallback={<Loading />}>{children}</Suspense>
        </ErrorBoundary>
      )}
    </QueryErrorResetBoundary>
  );
};
export default ErrorWrapper;
