import { createRootRoute, Outlet } from "@tanstack/react-router";

// Local
// import { TanStackRouterDevtools } from "../layout/devtools";
import MainStageContainer from "../layout/MainStageContainer";
import ResponsiveAppBar from "../navigation/AppBar";

import { Box } from "@mui/material";
import ErrorWrapper from "../navigation/ErrorWrapper";

import ChatBotWrapper from "../components/ChatBot/ChatBotWrapper";
import { Suspense } from "react";
import Loading from "../navigation/Loading";
// Root route: AppBar + BaseContainer + Devtools
// Base container is the main content area
export const Route = createRootRoute({
  component: () => (
    <>
      <Box
        id="root-route-container"
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          overflow: "hidden",
        }}
      >
        <ResponsiveAppBar />
        <MainStageContainer>
          <Suspense fallback={<Loading />}>
          <ErrorWrapper>
            <Outlet />
            <ChatBotWrapper />
          </ErrorWrapper>
          </Suspense>
        </MainStageContainer>
      </Box>
      {/* <TanStackRouterDevtools /> */}
    </>
  ),
});
