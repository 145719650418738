import { createFileRoute, useSearch } from "@tanstack/react-router";
import { loadStripe } from "@stripe/stripe-js";
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from "@stripe/react-stripe-js";
import { useCallback } from "react";
import { axiosRequest } from "../utils/axiosRequest";
import { useUser } from "../hooks/useUser";
import { UserClass } from "../models/Users";
import PleaseLogin from "../navigation/PleaseLogin";


// Set Stripe publishable key based on the environment
const isProd = window.location.origin === "https://www.socialhistory.ai";
const publishableKey = isProd
  ? "pk_live_51Q8XkWRvfd1bjxjBfTaL8Ojj7Yao2z6ued4E1WjQlMpxqSbiISz1jxlVJK3nejelmnPMWnQPEGdbPRgmdnWgsAO000aqDjwQyh"
  : "pk_test_51Q8XkWRvfd1bjxjBG5k3Y7jN37pMG3iSYETcyPtoOdSAEmMk4vp1jHqUS7wmRVK3qsx2p2tdoozYQSYzyCRAXqHZ00MZ8caQW2";
const stripePromise = loadStripe(publishableKey);

export const Route = createFileRoute("/checkout")({
  component: CheckoutWrapper,
});

// Wrapper component to handle user loading and error handling
function CheckoutWrapper() {
  const { user, isLoading } = useUser();

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <PleaseLogin text={"to buy credits."} />;
  }

  return <CheckoutComponent user={user} />;
}

interface Props {
  user: UserClass;
}

/**
 * The Checkout component handles the Stripe checkout process.
 * It fetches the client secret for creating a Checkout Session
 * and manages the embedded checkout UI.
 */
function CheckoutComponent({ user }: Props) {
  const { credits, domain }: { credits: number; domain: string } = useSearch({ from: "/checkout" });

  // Log the query parameters
  console.log("Credits:", credits);
  console.log("Domain:", domain);

  // Fetch Stripe client secret
  const fetchClientSecret = useCallback(async () => {
    // Create a Checkout Session
    console.log("user", user);
    try {
      const response = await axiosRequest({
        method: "POST",
        endpoint: "/createCheckoutSession",
        data: {
          email: user.email,
          credits: credits,
          domain: domain,
        },
      });
      return response.clientSecret;
    } catch (error) {
      console.error("Error fetching client secret:", error);
      throw error;
    }
  }, [user, credits, domain]);

  const options = { fetchClientSecret };

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  );
}
