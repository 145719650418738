import { useMutation } from "@tanstack/react-query";
import { axiosRequest } from "../utils/axiosRequest";

const useSendMagicLink = () => {
  return useMutation({
    mutationFn: (email: string) => {
      const domain = window.location.origin;
      return axiosRequest({
        method: "POST",
        endpoint: "/login",
        data: { email, domain },
      });
    },
  });
};

export default useSendMagicLink;
